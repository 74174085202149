<template lang="pug">
  .animated.fadeIn
    form-wrapper(:validator="$v.company")
      form.new
        app-panel
          app-header Company details

          app-text-field(class="w-1/2", name="name", label="Name", :value="company.name", @input="update('name', $event)")
          app-text-field(class="w-1/3", name="email", label="Email address", :value="company.email", @input="update('email', $event)")
          app-text-field(class="w-1/4", name="address1", label="Address line 1", description="Building number or name", :value="company.address1", @input="update('address1', $event)")
          app-text-field(class="w-1/4", name="address2", label="Address line 2", description="Road name", :value="company.address2", @input="update('address2', $event)")
          app-text-field(class="w-1/4", name="address3", label="Address line 3", description="County or region", :value="company.address3", @input="update('address3', $event)")
          app-text-field(class="w-1/6", name="postcode", label="Postcode", :value="company.postcode", @input="update('postcode', $event)")

        app-panel
          app-header Configuration

          app-dropdown-field.flex-1(class="w-1/4", v-if="timezones", name="saTimezone", label="Timezone", :value="company.saTimezone", :options="timezonePossibleValues", value-attr="value", @input="update('saTimezone', $event)")
          CheckboxesField.mb-4(v-if="newCompany === false" name="workingWeek" description="What are the working days of the company?" @input="workingDaysUpdated($event)" :value="checkedWorkingDays" :options="weekDays")

        app-panel(v-if="newCompany")
          app-header Trial details

          app-checkbox(label="Setup a trial?", :value="company.isTrial", @input="update('isTrial', $event)")

        //- app-panel
        //-   app-header Configuration

        app-panel(v-if="newCompany")
          app-header Company owner

          app-text-field(class="w-1/2", name="adminName", label="Name", :value="company.adminName", @input="update('adminName', $event)")
          app-text-field(class="w-1/3", name="adminEmail", label="Email address", :value="company.adminEmail", @input="update('adminEmail', $event)")

        .mb-8
          app-button(primary, @click.prevent="submit()") {{ submitLabel }}
          app-button.ml-8(@click.prevent="$emit('cancel')") Cancel

</template>
<script>
import { required } from "vuelidate/lib/validators";
import clone from "ramda/src/clone";
import TimezonesQuery from "@/graphql/queries/Timezones.gql";
import CheckboxesField from "@/components/collections/CheckboxesField.vue";
import { camelcase } from "@/helpers/StringHelpers";

export default {
  components: {
    CheckboxesField
  },
  apollo: {
    timezones: TimezonesQuery
  },
  data() {
    return {
      company: clone(this.value),
      isLoading: false,
      weekDays: [
        { name: "Sun", value: "sun" },
        { name: "Mon", value: "mon" },
        { name: "Tue", value: "tue" },
        { name: "Wed", value: "wed" },
        { name: "Thu", value: "thu" },
        { name: "Fri", value: "fri" },
        { name: "Sat", value: "sat" }
      ],
    };
  },
  computed: {
    submitLabel() {
      if (this.newCompany) {
        return "Create";
      }
      return "Update";
    },
    timezonePossibleValues() {
      const { possibleValues = [] } = this.timezones;

      if (possibleValues) {
        return possibleValues
          .map(tz => ({ label: tz.value, value: tz.value }))
          .sort((optA, optB) => optA.label.localeCompare(optB.label));
      }

      return [];
    },
    workingWeek() {
      return this.company.workingWeek;
    },
    checkedWorkingDays() {
      let workingWeek = [];

      for (let day in this.workingWeek) {
        if (this.company.workingWeek[day]) {
          workingWeek.push(camelcase(day));
        }
      }

      return workingWeek;
    }
  },
  methods: {
    submit() {
      if(!this.isLoading) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.isLoading = true;

        this.$emit("submit");
      }
    },
    update(key, value) {
      this.company[key] = value;
      this.$emit("input", { ...this.company, [key]: value });
    },
    workingDaysUpdated(checkedDays) {
      for (let { value } of this.weekDays) {
        this.$set(this.workingWeek, value, checkedDays.includes(value));
      }
      this.$emit("input", this.company);
    }
  },
  validations() {
    if (this.newCompany) {
      return {
        company: {
          name: {
            required,
          },
          email: {
            required,
          },
          adminName: {
            required,
          },
          adminEmail: {
            required,
          },
          saTimezone: {
            required,
          },
        },
      };
    } else {
      return {
        company: {
          name: {
            required,
          },
          email: {
            required,
          }
        },
      };
    }
  },
  props: {
    newCompany: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
